import { Injectable } from '@angular/core';
import { RewardCategoryModel } from './model/reward-category.model';
import { RequestData } from 'src/app/common/models/request-data.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RewardCategoryService {

  constructor(private http: HttpClient) { }

  getCategoryList(requestData:RequestData): Observable<any> {
    return this.http.post(environment.baseUrl+'/reward_category/get', requestData);

  }

  /**
   * 
   * @param categoryInfo 
   * Calls the update API to insert/update the record
   */
  update(categoryInfo: RewardCategoryModel): Observable<any> { 
    if(categoryInfo.hasOwnProperty("id")){
      return this.http.post(environment.baseUrl+'/reward_category/update', categoryInfo);
    } else{
      return this.http.post(environment.baseUrl+'/reward_category/add', categoryInfo);
    }
  }

  /**
   * 
   * @param categoryInfo 
   * Calls the API to delete the record
   */
  delete(categoryInfo: RewardCategoryModel): Observable<any>  {
    return this.http.post(environment.baseUrl+'/reward_category/delete/'+categoryInfo.id, categoryInfo);
  }
}
