<div class="user-group-component-container">
  <div class="page-header">
    <div class="page-title">User Group</div>
    <div class="page-actions">
      <button mat-button color="primary" class="btn-add" (click)="addClicked()">
        <mat-icon>person_add</mat-icon>New
      </button>
      <button mat-button color="accent" class="btn-export">
        <mat-icon>save_alt</mat-icon>Export
      </button>
    </div>
  </div>

  <div class="data-container">
    <app-loader [show]="isLoading"></app-loader>

    <!-- <app-search-filter
      [length]="pagingData.length"
      [pageSize]="pagingData.pageSize"
      [pageSizeOptions]="[5, 10, 20, 50, 100]"
      [pageIndex]="pagingData.pageIndex"
      [filter]="filter"
      (page)="onePageEvent($event)"
      (onApply)="onFilterApplied($event)"
      [hidePageSize]="true" #searchFilter
    >
    </app-search-filter> -->
    <app-search-filter #searchFilter [pagingData]="pagingData" 
    [pageSizeOptions]="[5,10,20,50,100]" [filter]="filter"
    (page)="onePageEvent($event)" (onApply)="onFilterApplied($event)" [hidePageSize]="true">
</app-search-filter>
    <section tabindex="0" class="table-container">
      <table matSort mat-table [dataSource]="dataSource" > 
        <ng-container matColumnDef="item">
          <th  mat-header-cell *matHeaderCellDef (click)="sortData($event)" class="item-header">#</th>
          <td  mat-cell *matCellDef="let element; let i = index" class="item-cloum">{{ (pagingData.pageIndex*pagingData.pageSize)  + i + 1 }}</td>
        </ng-container>
        <ng-container matColumnDef="code">
          <th
            mat-sort-header="code"
            mat-header-cell
            style="width: 80px"
            *matHeaderCellDef (click)="sortData($event)"
          >
            Code
          </th>
          <td mat-cell *matCellDef="let ug" style="width: 80px">
            {{ ug.code }}
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-sort-header="name" mat-header-cell *matHeaderCellDef (click)="sortData($event)">Name</th>
          <td mat-cell *matCellDef="let ug">{{ ug.name }}</td>
        </ng-container>
        <ng-container matColumnDef="is_valid">
          <th mat-sort-header="is_valid" mat-header-cell *matHeaderCellDef (click)="sortData($event)" class="status-header">
            Status
          </th>
          
          <td mat-cell *matCellDef="let ug" (click)="sortData($event)" class="status-column">
            <div class="ug-active-status" >
              <div *ngIf="ug.is_valid === 1" class="status active">
                Active <span></span>
              </div>
              <div *ngIf="ug.is_valid === 0" class="status expired">
                Inactive
              </div>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="action" stickyEnd>
          <th mat-header-cell *matHeaderCellDef></th>
          <td
            mat-cell
            class="row-menu-btn"
            style="width: 50px"
            *matCellDef="let rowItem"
          >
            <a
              mat-icon-button
              class="row-menu-btn"
              [matMenuTriggerFor]="rowMenu"
              (click)="onRowSelected(rowItem); $event.stopPropagation()"
            >
              <mat-icon>more_vert</mat-icon>
            </a>

            <mat-menu #rowMenu>
              <button mat-menu-item (click)="onDelete(rowItem)">Delete</button>
              <button mat-menu-item (click)="onEdit(rowItem)">Edit</button>
              <button mat-menu-item (click)="setPermission(rowItem)">
                Set Permissions
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr
          mat-row
          class="table-element-row"
          *matRowDef="let ug; columns: displayedColumns"
          (click)="onRowSelected(ug)"
          (dblclick)="onEdit(ug)"
          [ngClass]="{ 'row-selected': ug.id === selectedItem?.id }"
        ></tr>
      </table>
    </section>
    @if(pagingData.length<=0){
    <div class="no-data-container">
      <img src="assets/images/no-data.png" />
      <span class="no-data-info"
        >No data found. Please remove or redefine the filters if any.</span
      >
    </div>
    }
  </div>
</div>
<app-user-group-summary
  [user_group]="selectedItem"
  (onEdit)="onEdit($event)"
  (onDelete)="onDelete($event)"
></app-user-group-summary>
