<div class="login-container">
  <mat-card class="login-card">
    <!-- <mat-card-header>
        <div class="login-header-container">
          <img class="login-header" src="assets/images/app-title-logo.png">
        </div>
      </mat-card-header> -->
    <div class="login-overlay"></div>
    <mat-card-content>
      <div class="info-container">
        <img src="assets/images/crm-login.png" alt="">
      </div>
      
      <div class="login-form-container">
        <div class="version-info-container">
          <span>{{versionInfo}}</span>
        </div>
        <div class="login-title">
          LOGIN
        </div>
        <form class="login-form" [formGroup]="loginForm" (ngSubmit)="proceedUserLogin()">

          <div class="user-cred">
            <mat-form-field class="user-name">
              <mat-label>Username</mat-label>
              <input matInput formControlName="user_id">
            </mat-form-field>
            <mat-form-field class="password">
              <mat-label>Password</mat-label>
              <input matInput type="password" formControlName="password">
            </mat-form-field>
          </div>
          <div>
            <button mat-raised-button color="primary">Login</button>
          </div>

        </form>
      </div>
    </mat-card-content>
  </mat-card>

</div>