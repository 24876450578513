import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToolbarService {

  private toolBarSubject=new Subject<void>();

  menuToggleEvent=this.toolBarSubject.asObservable();

  constructor() { }

  /**
   * Emit the menu toggle click
   */
  onToggleOptionBar():void{
    this.toolBarSubject.next();
  }
}
