<div class="order-category-component-container">
        <div class="page-header">
                <button mat-button class="nav-button" (click)="navBack()" *ngIf="menuOption.search">
                        <mat-icon>arrow_back</mat-icon>
                </button>
                <div class="page-title">{{getTitle()}}</div>
                <div class="page-actions">
                </div>
        </div>

        <div class="data-container ">
                <app-loader [show]="isLoading"></app-loader>

                <!-- <app-search-filter #searchFilter [length]="pagingData.length" [pageSize]="pagingData.pageSize"
                        [pageSizeOptions]="[5,10,20,50,100]" [pageIndex]="pagingData.pageIndex" [filter]="filter"
                        (page)="onePageEvent($event)" (onApply)="onFilterApplied($event)" [hidePageSize]="true"
                        [hideAdvancedFilter]="hideAdvancedFilter">
                </app-search-filter> -->
                <app-search-filter #searchFilter [pagingData]="pagingData" 
                [pageSizeOptions]="[5,10,20,50,100]" [filter]="filter"
                (page)="onePageEvent($event)" (onApply)="onFilterApplied($event)" [hidePageSize]="true">
        </app-search-filter>

                <section tabindex="0" class="table-container">
                        <table matSort mat-table [dataSource]="dataSource">
                                <ng-container matColumnDef="item">
                                        <th mat-header-cell *matHeaderCellDef (click)="sortData($event)" class="item-header">#</th>
                                        <td mat-cell *matCellDef="let order; let i = index" class="item-column">{{
                                                (pagingData.pageIndex*pagingData.pageSize) + i + 1 }}</td>
                                </ng-container>

                                <ng-container matColumnDef="order_time">
                                        <th mat-sort-header="order_time" mat-header-cell *matHeaderCellDef
                                                (click)="sortData($event)" class="order-date-header">Order Date
                                        </th>
                                        <td mat-cell *matCellDef="let order" class="order-date-column"> {{ order.order_time | date: dateFormat }}
                                        </td>
                                </ng-container>

                                <ng-container matColumnDef="shop_name">
                                        <th mat-sort-header="shop_name" mat-header-cell *matHeaderCellDef
                                                (click)="sortData($event)" style="width: auto;">Shop Name
                                        </th>
                                        <td mat-cell *matCellDef="let order" style="width: auto;"> {{order.shop_name}}
                                        </td>
                                </ng-container>
                                <ng-container matColumnDef="order_id">
                                        <th mat-sort-header="order_id" mat-header-cell *matHeaderCellDef
                                                (click)="sortData($event)" class="order-id-header">Order Id </th>
                                        <td mat-cell *matCellDef="let order" class="order-id-column"> {{order.order_id}}
                                        </td>
                                </ng-container>
                                <ng-container matColumnDef="crm_customer_id">
                                        <th mat-sort-header="crm_customer_id" mat-header-cell *matHeaderCellDef
                                                (click)="sortData($event)">Cust Id
                                        </th>
                                        <td mat-cell *matCellDef="let order"> {{order.crm_customer_id}}
                                        </td>
                                </ng-container>
                                <ng-container matColumnDef="total_qty">
                                        <th mat-sort-header="total_qty" mat-header-cell *matHeaderCellDef
                                                 (click)="sortData($event)" class="qty-header">
                                                Qty </th>
                                        <td mat-cell *matCellDef="let order"
                                                class="qty-column">
                                                {{order.qty}}
                                        </td>
                                </ng-container>
                                <ng-container matColumnDef="total_amount">
                                        <th mat-sort-header="total_amount" mat-header-cell *matHeaderCellDef
                                                 (click)="sortData($event)" class="amount-header">
                                                Total Amount
                                        </th>
                                        <td mat-cell *matCellDef="let order"  class="amount-column">
                                                {{ order.total_amount | currency:'USD':'symbol':'1.2-2' }}
                                        </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                                </tr>
                                <tr mat-row class="table-element-row" *matRowDef="let order; columns: displayedColumns;"
                                        (click)="onRowSelected(order)"
                                        [ngClass]=" {'row-selected' : order.order_id===selectedItem?.order_id}">
                                </tr>
                        </table>
                </section>
                @if(pagingData.length<=0){ <div class="no-data-container">
                        <img src="assets/images/no-data.png">
                        <span class="no-data-info">No data found. Please remove or redefine the filters if
                                any.</span>
        </div>
        }
</div>

</div>
<app-order-summary [order]="selectedItem"></app-order-summary>