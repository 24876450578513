<div class="summary-page-root-container user-summary-root-container"
    [style.width]=" show ? '320px' : '0px' ">
    <div [ngClass]="{ 'close-btn' : show , 'open-btn' :!show  }">
        <button mat-icon-button (click)="close()">
            @if(show){
            <mat-icon>arrow_forward</mat-icon>
            }@else{
            <mat-icon >arrow_back</mat-icon>
            }
        </button>
    </div>
    @if(show && user!==undefined){
    <div class="page-container">
        <div class="page-header">
            <div class="title">{{user.name}} </div>

        </div>
        <div class="page-content">
            <div class="section description-container">
                <span>{{user.email}}</span>
            </div>

            <div class="section status-container">
                <div class="reward-category-actieve-status">
                    @if(user.is_valid!==0){
                    <div class="status active">
                        Active
                    </div>
                }@else{
                    <div class="status inactive">
                        Inactive
                    </div>
                }
                </div>
            </div>
            <div class="section-content" style="margin-left: 10px; margin-top: 10px">
                <ng-container>
                    <strong>Created</strong><br/> Admin &nbsp;&nbsp;&nbsp;&nbsp;
                    {{user.created_at | date :dateTimeFormat }} </ng-container><br /><br />
                <ng-container>
                    <strong>Updated</strong><br/>  Admin &nbsp;&nbsp;&nbsp;&nbsp;
                    {{user.updated_at| date : dateTimeFormat}}
                </ng-container>
            </div>

        </div>
        <div class="page-footer">
            <button mat-button color="primary" class="btn-edit"
                (click)="onEditAction()"><mat-icon>edit</mat-icon>Edit</button>
            <button mat-button color="accent" class="btn-delete" (click)="onDeleteAction()">
                <mat-icon>delete</mat-icon>Delete</button>
        </div>
    </div>
}@else if(show && user==undefined)
{

    <div class="no-page-data">
        <img src="assets/images/side-bar-no-data.png">
        <span class="no-data-info">Nothing to show. Please select a reward category.</span>
    </div>
}
@else {}

</div>