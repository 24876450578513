<div class="summary-page-root-container tag-summary-root-container" [style.width]=" show ? '320px' : '0px' ">
    <div [ngClass]="{ 'close-btn' : show , 'open-btn' :!show  }">
        <button mat-icon-button (click)="close()">
            <mat-icon *ngIf="show">arrow_forward</mat-icon>
            <mat-icon *ngIf="!show">arrow_back</mat-icon>
        </button>
    </div>
    <div *ngIf="show && tag!==undefined" class="page-container">
        <div class="page-header">
            <div class="title">{{tag.code}} </div>

        </div>
        <div class="page-content">
            <div class="section description-container">
                <span>{{tag.description}}</span>
            </div>
            <div class="section status-container">
                <div class="tag-actieve-status">
                    <div *ngIf="tag.is_valid==1" class="status active">
                        Active
                    </div>
                    <div *ngIf="tag.is_valid!==1" class="status inactive">
                        Inactive
                    </div>
                </div>
            </div>
            <div class="section created-info-container">
                <div class="section-content">
                    <span>Creatd At : {{tag.created_at | date:'dd MMM yyyy HH:mm' }} </span>
                    <span>Updated At : {{tag.updated_at | date:'dd MMM yyyy HH:mm' }} </span>
                </div>
            </div>
        </div>
        <div class="page-footer">
            <button mat-button color="primary" class="btn-edit"
                (click)="onEditAction()"><mat-icon>edit</mat-icon>Edit</button>
            <button mat-button color="accent" class="btn-delete" (click)="onDeleteAction()">
                <mat-icon>delete</mat-icon>Delete</button>
        </div>
    </div>

    <div class="no-page-data" *ngIf="show && tag==undefined">
        <img src="assets/images/side-bar-no-data.png">
        <span class="no-data-info">Nothing to show. Please select a tag.</span>
    </div>

</div>