<div  *ngIf="showPagingMenu" (click)="showPagingMenu=!showPagingMenu" class="overlay"></div>
<div class="filter-container mat-elevation-z0">
    <div class="header">
        <div class="search-input-container">

            <mat-divider [vertical]="true"></mat-divider>
            <div class="free-search">
                <button mat-button class="search-icon" (click)="onFilterApply()">
                    <mat-icon>search</mat-icon>
                </button>
                <div class="search-input">
                    <input class="search-input" placeholder="Search..." [(ngModel)]="freeSearchValue"
                        (keyup.enter)="onFilterApply()">
                    <button *ngIf="freeSearchValue" matSuffix mat-icon-button aria-label="Clear"
                        (click)="freeSearchValue='';">
                        <mat-icon
                            style="color: rgb(29 28 28 / 38%); font-weight: 500;  font-size: 22px;">close</mat-icon>
                    </button>
                </div>
                @if(!hideAdvancedFilter){
                <div class="adv-option">
                    <button mat-flat-button (click)="onToggleFilter()" class="filter-toggle">
                        <!-- <mat-icon
                            [style.transform]="showContent ? 'rotate(180deg)' : 'rotate(0)'">filter_list</mat-icon>Filter -->
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
				<path 
				    d="M3 17v2h6v-2H3zM3 5v2h10V5H3zm10 16v-2h8v-2h-8v-2h-2v6h2zM7 9v2H3v2h4v2h2V9H7zm14 4v-2H11v2h10zm-6-4h2V7h4V5h-4V3h-2v6z">
				</path>
			  </svg>
                        </button>
                    <div class="filter_counter">
                        {{filterInfo}}
                    </div>
                </div>
                }
            </div>
        </div>
        <div class="paginator-container">
            <div  *ngIf="showPagingMenu" class="page-menu-container">
                <div class="items-per-page">
                    <select [(ngModel)]="pagingData"  class="page-size-select"  (ngModelChange)="onPageSizeChanged()">
                        <option class="page-size-select-option" *ngFor="let value of pageSizeOptions" [value]="value">{{value}}</option>
                      </select>
                </div>
                <button mat-button [disabled]="!paginator?.hasPreviousPage" class="btn-navigation" (click)="onFirstPage()"><mat-icon>first_page</mat-icon></button>
                <button mat-button [disabled]="!paginator?.hasNextPage" class="btn-navigation" (click)="onLastPage()"><mat-icon>last_page</mat-icon></button>
                <button mat-button class="btn-navigation" (click)="onClosePageMenu()"><mat-icon>close</mat-icon></button>
            </div>
            <mat-paginator  class="paginator" showFirstLastButtons (page)="onePageEvent($event)"
                [length]="pagingData.length" [pageSize]="pagingData.pageSize" [pageSizeOptions]="[5,10,20,50,100]" [pageIndex]="pagingData.pageIndex"
                [hidePageSize]="hidePageSize" [showFirstLastButtons]="false" aria-label="Select page">
            </mat-paginator>
        </div>
        <!-- <app-sorter></app-sorter> -->
    </div>
    <div class="filter-content" [style.height]="showContent ? '190px' : '0px' "
        [style.border-width]="showContent ? '1px' : '0px' ">
        <ng-container *ngIf="selectedFilters.length>0; else nofilter">
            <div class="filter-content-container">
                <app-filter-item *ngFor="let filter of selectedFilters; let i = index" class="filter-item-content"
                    [filter]="filter" [search]="selectedColumnFilter[i]" (remove)="onFilterRemove(i)"></app-filter-item>
            </div>
        </ng-container>
        <ng-template #nofilter>
            <div class="empty-filter">
                <div class="empty-filter-contaiiner">
                    <img class="filter-icon" src="assets/images/no-filter.png">
                    <span class="no-filter-info">
                        No filters have been created yet. To create new filters, click on the  <span class="btn-add">Add</span>  button, or if you have saved filters, click on the <span class="btn-load">Load</span> button.
                    </span>
                </div>
            </div>
        </ng-template>
    </div>
    <div class="filter-footer" [style.height]="showContent ? '50px' : '0px' ">
        <button [disabled]="selectedColumnFilter.length==0"
            [style.background]="selectedColumnFilter.length==0?'lightgray':null" class="filter-template-button save"
            mat-button color="warn" (click)="saveClicked()">Save</button>
        <button class="filter-template-button btn-load" mat-button color="warn" (click)="onFilterLoad()">Load </button>
        <button class="btn-reset" mat-button color="warn" (click)="onFilterReset()">Reset</button>
        <button class="btn-add" mat-button color="primary" (click)="onFilterAdd()">Add</button>
        <button class="btn-apply" mat-button color="accent" (click)="onFilterApply()">Apply</button>
    </div>
</div>
