import { Injectable } from '@angular/core';
import { UserGroupModel } from './model/user-group.model';
import { RequestData } from 'src/app/common/models/request-data.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserGroupService {

  constructor(private http: HttpClient) { }

  getUserGroupList(requestData:RequestData): Observable<any> {
    return this.http.post(environment.baseUrl+'/user-group/get', requestData);

  }

  /**
   * 
   * @param userGroupInfo 
   * Calls the update API to insert/update the record
   */
  update(userGroupInfo: UserGroupModel): Observable<any> { 
    if(userGroupInfo.hasOwnProperty("id")){
      return this.http.post(environment.baseUrl+'/user-group/update', userGroupInfo);
    } else{
      return this.http.post(environment.baseUrl+'/user-group/add', userGroupInfo);
    }
  }

  /**
   * 
   * @param userGroupInfo 
   * Calls the API to delete the record
   */
  delete(userGroupInfo: UserGroupModel): Observable<any>  {
    return this.http.post(environment.baseUrl+'/user-group/delete/'+userGroupInfo.id, userGroupInfo);
  } 

   /**
   * 
   * Calls the API to get module list
   */
  getModuleList(): Observable<any> {
    return this.http.post(environment.baseUrl+'/application/get',[]);
  }

   /**
   * 
   * @param userGroupId 
   * Calls the API to get user group data for edit
   */
  getUserGroupById(userGroupId: number): Observable<any> {
    return this.http.post(environment.baseUrl+'/user-group/get-edit/'+userGroupId,[]);

  }

}
