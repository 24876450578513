import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { RewardModel } from './model/reward.model';
import { FloatingMenuOption } from 'src/app/common/components/master-floating-menu/model/master-floating-model';
import { ColumnFilter, RequestData, SearchSort } from 'src/app/common/models/request-data.model';
import { SelectionModel } from '@angular/cdk/collections';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';

import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/common/components/confirm-dialog/confirm-dialog.component';
import { RewardService } from './reward.service';
import { FilterListItemModel, SearchParam,SearchFilterComponent } from 'src/app/common/components/search-filter/search-filter.component';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { SystemSettingsService } from 'src/app/common/services/system-settings.service';
import { MatTableDataSource } from '@angular/material/table';
import { StateManagerService } from 'src/app/common/services/state.manager.service';
import { MatSort, SortDirection } from '@angular/material/sort';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reward',
  templateUrl: './reward.component.html',
  styleUrls: ['./reward.component.scss'],
  animations: [
    trigger('if', [
      state('show', style({ opacity: 1, height: '*' })),
      state('hide', style({ opacity: 0, height: '0' })),
      transition('show <=> hide', animate('300ms ease-in-out')),
    ]),
  ],
})
export class RewardComponent {

  itemList: RewardModel[]=[];
  showSearch: boolean = false;
  isLoading=true;

  filter:FilterListItemModel[]= [
    {
      title: "Code",
      column: "code",
    },
    {
      title: "Name",
      column: "name",
    },
    {
      title: "Category",
      column: "crm_reward_category_id",
      type:"list",
      values: []
    },
    {
      title: "Active",
      column: "is_active",
      type:"boolean",
      values: [
        { title: "Active", value: "1" },
        { title: "Not Active", value: "0" },
      ]
    },
    {
      title: "Reward Eligibility",
      column: "reward_eligibility",
      type:"boolean",
      values: [
        { title: "Public (Offers)", value: "1" },
        { title: "Registered Customers (Reward)", value: "2" },
      ]
    },
    {
      title: "Events",
      column: "crm_event_id",
      type:"boolean",
      values: [
        { title: "NEW CUSTOMER SIGNUP", value: "1" },
        { title: "BIRTHDAY", value: "2" },
        { title: "ANNIVERSARY", value: "3" },

      ]
    },
    {
      title: "Sales Channels",
      column: "sales_chanels",
      type:"list",
      values: [
        { title: "Online", value: "[1]" },
        { title: "In-Store", value: "[2]" },
        { title: "Oniline & In-Store", value: "[1,2]" },

      ]
    },
    {
      title: "Reward Type",
      column: "reward_type",
      type:"boolean",
      values: [
        { title: "Discount based on Order Value", value: "1" },
        { title: "Item Discount", value: "3" },
        { title: "Buy X Get Y", value: "4" },
        { title: "Free/Discount Item based on Order Value", value: "5" },
      ]
    },
    {
      title: "Date",
      column: "published_on",
      type:"date",
    },
    {
      title: "Status",
      column: "status",
      type:"list",
        values: [
        { title: "Published", value: "1" },
        { title: "Unpublished", value: "0" },
        { title: "Exipred", value: "2" },
      ]
    },
  ];

  displayedColumns = ['item','code', 'name', 'crm_reward_category_id','status','reward_eligibility','published_on','valid_until','action'];
  pagingData = { length: 0, pageSize: 10, pageIndex: 0 };

  menuOption: FloatingMenuOption = {
    add: true,
    search: false,
    export: true,
    more: true
  }
  isHandset: boolean = false;
  searchColumFilter: ColumnFilter[]=[];
  columnSortData: SearchSort[]=[];
  selection = new SelectionModel<RewardModel>(true, []);
  categories=[];
  selectedItem?: RewardModel;
  showRewardSummary: boolean=false;
  selectedRewardFilter: undefined;
  dateFormat: string;
  dataSource = new MatTableDataSource(this.itemList);
  constructor(private breakpointObserver: BreakpointObserver, 
              private itemService: RewardService, 
              private dialog: MatDialog, 
              private snackBarService: SnackBarService,
              private router:Router,
              private systemSettingService: SystemSettingsService,private stateManagerService: StateManagerService) {

    this.itemList = [];
    this.dateFormat = systemSettingService.getDefaultDateFormat();
  }

  /**
   * initilaize the device model
   */
  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isHandset = true;
      } else {
        this.isHandset = false;
      }
    });
    this.isLoading = false;

    this.loadCategories();
   // this.loadData();

  }

  loadCategories(): void{
    let response = this.itemService.getCategories().subscribe(response => {
      if (response.status == 'SUCCESS') {
        this.categories = response.data.categories;
        this.filter[2].values=(this.categories.map((cat:any)=> {
          return {title:cat.name,value:cat.id};
        }));
      } else {
        let snackBarData:SnackBarData={
          message:response.message,
          title:'Failed!!!',
          type:'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
      this.isLoading=false;
    });
  }
   /** 
  * Set the datasource
  */
   setDataSource(itemList:RewardModel[]){
    this.dataSource=new MatTableDataSource(itemList);
    this.dataSource.sort=this.sort;
  }

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('searchFilter') searchFilter!: SearchFilterComponent;
  ngAfterViewInit(): void {

    this.loadPreviousState();
    // this.loadData(); 
  }

  /**
   * loads the previous state if exists
   */
  loadPreviousState(): void {

    var previousState = this.stateManagerService.getState(this.constructor.name);

    if (previousState !== undefined && this.searchFilter != undefined) {

      if (previousState.state.search.filters.scope) {
        this.pagingData.pageIndex = previousState.state.search.filters.scope.offset / previousState.state.search.filters.scope.limit;
        this.pagingData.pageSize = previousState.state.search.filters.scope.limit;
      }

      if (previousState.state.search.sort != undefined && previousState.state.search.sort.length > 0) {
        this.setSortColumn(previousState.state.search.sort[0].column,previousState.state.search.sort[0].order);
      }

      this.searchColumFilter = JSON.parse(JSON.stringify(previousState.state.search.filters));
      this.searchFilter.setSearchFilter(this.searchColumFilter,true);

    } else {
      this.setSortColumn('name','asc');
      this.loadData();
    }

  }

  /**
   * Sets the sort column
   * @param column 
   * @param direction 
   */
  setSortColumn(column: string, direction: SortDirection): void {
    this.columnSortData.length = 0;
    this.columnSortData.push({ column: column, order: direction});
    this.sort.sort({
      id: column,
      start: direction,
      disableClear: false,
    });

    // this.sort.active=column;
    // this.sort.direction=direction;
    

  }


  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.itemList.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.itemList);
  }


  /**
   * Create sort order
   * @returns sort criteria
   */
  getSort(): SearchSort[] {
    return  this.columnSortData;
  }
   /**
   * Calculates Exipry Date
   */
   getUpdatedDate(item: any): string {
    if (item.status === 2) {
      const publishedDate = new Date(item.published_on);
      const validForDays = item.valid_for || 0;
      const updatedDate = new Date(publishedDate.getTime() + validForDays * 24 * 60 * 60 * 1000);
      return updatedDate.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
    } else {
      return new Date(item.published_on).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
    }
  }
  /**
   * Creates the column filter conditions based on search criteria
   * @returns Filter condions for columns
   */
  getColumnFilter(): ColumnFilter[] {

    return this.searchColumFilter;
  }
    /**
   * Creates the column filter conditions based on search criteria
   * if customers are selected and filtering for selected customer add filtered customer ids
   * @returns Filter condions for columns
   */
    getSearchParam(): SearchParam {

      let searchParam = JSON.parse(JSON.stringify(this.searchColumFilter));
      if (this.selectedRewardFilter !== undefined) {
        if (searchParam.advSearch !== undefined)
          searchParam.advSearch.push(this.selectedRewardFilter);
        else
          searchParam.advSearch = [this.selectedRewardFilter];
      }
      return searchParam;
  
    }
  /**
   * Builds the request data to be send to API
   * @returns Request Data
   */
  buildRequestData(): RequestData {
    let searchParam = this.getSearchParam();
    let requestData: RequestData = {
      search: {
        filters: {
          simpleSearch: searchParam.freeSearch,
          advSearch: searchParam.advSearch,
          scope: {
            limit: this.pagingData.pageSize,
            offset: (this.pagingData.pageIndex * this.pagingData.pageSize),
          }
        },
        sort: this.getSort(),
      }
    };

    return requestData;
  }

  /**
   * loads the item data based on the conditions
   */
  loadData(): void {
    this.isLoading=true;
    let requestData = this.buildRequestData();
    let response = this.itemService.getList(requestData).subscribe(response => {
      if (response.status == 'SUCCESS') {
        //this.itemList = response.data.rewards;
        this.setDataSource(response.data.rewards );
        this.pagingData.length = response.data.total_count;
        if( this.pagingData.length ==0){
          let snackBarData:SnackBarData={
            message:'No data found. Please remove or change the filter if any.',
            title:'No Data!!!',
            type:'warn'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }else{
          this.stateManagerService.setState(this.constructor.name, requestData);
        }
      } else {
        let snackBarData:SnackBarData={
          message:response.message,
          title:'Failed!!!',
          type:'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
      this.isLoading=false;
    });

  }

  /**
   * On Search toggle button is clicked
   */
  toggleSearch(): void {
    this.showSearch = !this.showSearch
  }

  /**
   * On Add clicked
   * Show the dialog to enter new user
   */
  addClicked(): void {

    this.router.navigate(['reward-edit',0]);
  }
  exportClicked() {
    this.isLoading = true;
    let requestData = this.buildRequestData();
  
    this.itemService.downloadExcel(requestData)
      .subscribe(
        blob => {
          // Download logic
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'reward.xlsx'; // Adjust filename if needed
          link.click();
          window.URL.revokeObjectURL(url);
          this.isLoading = false;
        },
        error => {
          // Handle errors
          console.error('Error downloading Excel:', error);
          this.isLoading = false;
          // Display user-friendly error message to the user
        }
      );
  }

  /**
   * 
   * @param event 
   * When page size is changed update paging data
   */
  onePageEvent(event: any): void {
    
    this.pagingData.pageIndex = event.pageIndex;
    this.pagingData.pageSize = event.pageSize;

    this.loadData();

  }



  /**
 * On edit the item
 * Show the edit dialog
 */
  onEdit(itemInfo: RewardModel): void {

    this.router.navigate(['reward-edit',itemInfo.id]);


  }

  /**
   * Set the column filter and reaload the daaa
   * @param columFilter 
   */
  onFilterApplied(columFilter:ColumnFilter[]): void{
    this.searchColumFilter=columFilter;
    this.loadData();
    
  }

  /**
   * On delete menu item selected
   * Confirm the user action and call the api to update
   */
  onDelete(itemInfo: RewardModel): void { 
    const userId = itemInfo.id;
    const message = `Are you sure, you want to delete ` + (itemInfo.name  + "?");
    const dialogData = new ConfirmDialogModel("Confirm Deletion", message);
    
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => { console.log(dialogResult);
      if (dialogResult) { 
        this.itemService.delete(itemInfo).subscribe({
          next: (response) => {
            if (response.status == 'SUCCESS') {
              // handle success
              let snckBarData: SnackBarData = {
                type: 'success',
                title: 'Saved',
                message: 'The record for ' + itemInfo.name  + " has been deleted succesfully."
              }
              this.snackBarService.openSnackBar(snckBarData);
              this.loadData();
            } else {
              let snackBarData:SnackBarData={
                message:response.message,
                title:'Failed!!!',
                type:'error'
              }
              this.snackBarService.openSnackBar(snackBarData);
            }
          },
          error: (error) => {
            let snackBarData:SnackBarData={
              message:'Failed to update. Connection to the server failed',
              title:'Failed!!!',
              type:'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
          }
        });
      }
    });
  }

  /**
   *  Set the sort param and relead the data
   * @param $event sort data
   * {"active": "email","direction": "asc"}
   *   
   */
  sortData($event: any): void {
    var sd=this.sort;
    this.columnSortData.length = 0;
    this.columnSortData.push({ column: sd.active, order: sd.direction });
    this.loadData();
  }

      /** 
     * The label for the checkbox on the passed row 
     * */
      checkboxLabel(row?: RewardModel): string {
        // if (!row) {
        //   return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
        // }
        // return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
        return '';
      }

      /**
   * On reward selected
   * @param rewardInfo 
   */
    onRowSelected(rewardInfo: RewardModel): void {
      this.selectedItem = rewardInfo;
      this.showRewardSummary = true;
    }

}