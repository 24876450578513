<div class="summary-page-root-container user-group-summary-root-container" [style.width]=" show ? '320px' : '0px' ">
    <div [ngClass]="{ 'close-btn' : show , 'open-btn' :!show  }">
        <button mat-icon-button (click)="close()">
            @if(show){
            <mat-icon>arrow_forward</mat-icon>
            }
            @else{
            <mat-icon>arrow_back</mat-icon>
            }
        </button>
    </div>
    @if(show && user_group!==undefined){
    <div  class="page-container">
        <div class="page-header">
            <div class="title">{{user_group.code}} </div>

        </div>
        <div class="page-content">
            <div class="section description-container">
                <span>{{user_group.description}}</span>
            </div>
            <div class="section status-container">
                <div class="user-group-actieve-status">
                    <div *ngIf="user_group.is_valid==1" class="status active">
                        Active
                    </div>
                    <div *ngIf="user_group.is_valid!==1" class="status inactive">
                        Inactive
                    </div>
                </div>
            </div>
            <div class="section-content" style="margin-left: 10px; margin-top: 10px">
                <ng-container>
                    <strong>Created</strong><br/> Admin &nbsp;&nbsp;&nbsp;&nbsp;
                    {{user_group.created_at | date : dateTimeFormat }} </ng-container><br /><br />
                <ng-container>
                    <strong>Updated</strong><br/>  Admin &nbsp;&nbsp;&nbsp;&nbsp;
                    {{user_group.updated_at| date :  dateTimeFormat }}
                </ng-container>
            </div>
        </div>
        <div class="page-footer">
            <button mat-button color="primary" class="btn-edit"
                (click)="onEditAction()"><mat-icon>edit</mat-icon>Edit</button>
            <button mat-button color="accent" class="btn-delete" (click)="onDeleteAction()">
                <mat-icon>delete</mat-icon>Delete</button>
        </div>
    </div>
}@else if(show && user_group==undefined){

    <div class="no-page-data">
        <img src="assets/images/side-bar-no-data.png">
        <span class="no-data-info">Nothing to show. Please select a user group.</span>
    </div>
}
@else{}
</div>